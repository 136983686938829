<template>
  <v-app>
    <authenticator :hideSignUp="true">
      <template v-slot="{ signOut }">
        <v-app-bar :elevation="2" class="bg-primary">
          <template v-slot:prepend>
            <img style="height:100px; width: auto;" :src='require("/src/assets/AgentOS-Logo.png")' />
          </template>
          <template v-slot:append v-if="hasAccess">
            <div>
              <v-btn @click="navHome()">Home</v-btn>
              <v-btn @click="signOut">Sign Out</v-btn>
            </div>
          </template>
        </v-app-bar>
        <div id="main">
          <v-main>
            <div v-if="isLoading" class="text-center pa-4">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <div v-else-if="!hasAccess" class="text-center pa-4">
              <v-alert
                type="error"
                title="Access Denied"
                text="You do not have permission to access this application. Please contact your administrator."
                class="mx-auto max-w-md"
              ></v-alert>
              <v-btn color="primary" class="mt-4" @click="signOut">Sign Out</v-btn>
            </div>
            <div v-else>
              <router-view />
            </div>
          </v-main>
        </div>
      </template>
  
      <template v-slot:header>
        <div style="padding: var(--amplify-space-large); text-align: center">
          <img
            class="amplify-image"
            alt="Amplify logo"
            src="../src/assets/AgentOS-Logo.png"
          />
        </div>
        <br />
      </template>
      
      <template v-slot:sign-in-header>
        <h3
          class="amplify-heading"
          style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
        >
          Sign in to the agentOS affiliate management app
        </h3>
      </template>
    </authenticator>
  </v-app>
  </template>
  
  <script>
  import { Authenticator } from "@aws-amplify/ui-vue";
  import "@aws-amplify/ui-vue/styles.css";
  import { fetchAuthSession } from 'aws-amplify/auth';
  import { defineComponent } from "vue";
  import { Hub } from 'aws-amplify/utils';
  
  export default defineComponent({
    name: 'App',
    components: {
      Authenticator
    },
    data() {
      return {
        hasAccess: false,
        isLoading: true
      }
    },
    async mounted() {
      // Listen for auth events
      Hub.listen('auth', async ({ payload }) => {
        if (payload.event === 'signedIn') {
          await this.checkUserAccess();
        }
      });
      
      // Check initial auth state
      try {
        await this.checkUserAccess();
      } finally {
        this.isLoading = false;
      }
    },
    methods: {
      async checkUserAccess() {
        try {
          const { tokens } = await fetchAuthSession();
          if (tokens) {
            const groups = tokens.accessToken.payload['cognito:groups'] || [];
            this.hasAccess = groups.includes('affiliateAppUserGroup');
            
            if (!this.hasAccess) {
              console.log('User not in required group');
            }
          }
        } catch (error) {
          console.error('Error checking user access:', error);
          this.hasAccess = false;
        }
      },
      navHome() {
        this.$router.push('/');
      }
    }
  });
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 60px;
  }
  </style>